import api from "@/base/utils/request";
import download from "@/base/utils/download";

export const getIndex = data => {
  return api({
    url: "/admin/signup/signup_user/index",
    method: "post",
    data
  });
};

export const setDetail = data => {
  return api({
    url: "/admin/signup/signup_user/detail",
    method: "post",
    data
  });
};

//删除
export const del = data => {
  return api({
    url: "/admin/signup/signup_user/del",
    method: "post",
    data
  });
};

// 导出
export const orderDocument = data => {
  return download({
    url: "/admin/signup/signup_user/document",
    method: "post",
    data,
    download: true
  });
};
