<template>
  <div class="list-page">
    <el-button style="margin-bottom:20px" @click="toIndexPage">返回</el-button>

    <tube-filter v-model="filterForm" :uploadFilter="ok" />
    <el-table ref="multipleTable" :data="list" class="thead-light" stripe style="width: 100%;margin-top:20px"
      @sort-change="sortChange" v-loading="loading">
      <el-table-column label="用户名" min-width="120" show-overflow-tooltip>
        <template slot-scope="scope">{{scope.row.nickname | placeholder}}</template>
      </el-table-column>
      <el-table-column prop="create_time" label="提交时间" min-width="180" sortable="custom"></el-table-column>
      <el-table-column v-for="(field, index) in fieldList" :key="index" show-overflow-tooltip>
        <template slot="header" slot-scope="scope">{{field}}</template>
        <template slot-scope="scope">
          <div style="position: relative;width: 58px; height: 40px;"
            v-if="scope.row[field].type === 'images'">
            <el-button v-if="scope.row[field].name!=''" type="text">点击查看</el-button>
            <span v-if="scope.row[field].name==''">-</span>
            <el-image v-if="scope.row[field].name.length && scope.row[field].name[0]"
              style="width: 100%; height: 100%; vertical-align: middle;position: absolute;left: 0;"
              :src="scope.row[field].name[0] + '?fit=max&w=300'" :preview-src-list="scope.row[field].name">
            </el-image>
          </div>
          <div style="position: relative;width: 58px; height: 40px;"
            v-if="scope.row[field].type === 'videos'">
            <el-button v-if="scope.row[field].name!=''" @click="bofVideo(scope.row[field].name,2)"
              type="text">点击查看</el-button>
            <span v-if="scope.row[field].name==''">-</span>
          </div>
          <div style="position: relative;width: 58px; height: 40px;"
            v-if="scope.row[field].type === 'medias'">
            <template v-if="f.type=='image'">
              <el-image v-for="(f,i) in scope.row[field].name" style="width: 50px;height: 50px" :src="f.url"
                fit="cover" :preview-src-list="[f.url]" :key="i">
              </el-image>
            </template>
            <template v-if="f.type=='video'">
              <img @click="bofVideo(f,3)" v-for="(f,i) in scope.row[field].name"
                style="width: 50px;height: 50px" :key="i" :src="f.thumbnail" />
            </template>
          </div>
          <span v-if="!scope.row[field].type">{{scope.row[field] ? scope.row[field] : '--'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" @click="showDetail(scope.row.id)">查看详情</el-button>
          <el-divider direction="vertical"></el-divider>
          <el-button type="text" @click="delItem(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="查看详情" :visible.sync="openDialog" width="500px" :show-close="true"
      class="dialog-vertical" @close="cancel('CategoryName')">
      <div v-if="detail" v-loading="saveLoading">
        <el-row>
          <el-col :span="8">
            <div>提交人：{{detail.nickname | placeholder}}</div>
          </el-col>
          <el-col :span="16">
            <div>提交时间：{{detail.create_time}}</div>
          </el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row class="row-data" v-for="(item, index) in detail.UserConfig" :key="index">
          <el-col :span="6">
            <div class="p-r-10">{{item.name}}：</div>
          </el-col>
          <el-col :span="18">
            <template v-if="item.config && item.config.type === 'images'">
              <div class="flex flex-wrap" style="width: 100%;">
                <!-- <el-button type="text">点击查看</el-button>-->
                <el-image style="width: 90px; height: 90px" class="m-b-10 m-r-10" :src="fixImageUrl(image)"
                  v-for="(image, ii) in item.config.name" :key="ii" fit="cover"
                  :preview-src-list="item.config.name">
                </el-image>
              </div>
              <div class="flex flex-wrap" style="width: 100%;"
                v-if="!item.config.name || !item.config.name.length">
                <span type="text">-</span>
              </div>
            </template>
            <template v-else-if="item.config && item.config.type === 'videos'">
              <div class="flex flex-wrap" style="width: 100%;">
                <img @click="bofVideo(item.config.name,0)" style="width: 90px; height: 90px"
                  class="m-b-10 m-r-10" :src="image.thumbnail" v-for="(image, ii) in item.config.name"
                  :key="ii" />
              </div>
              <div class="flex flex-wrap" style="width: 100%;"
                v-if="!item.config.name || !item.config.name.length">
                <span type="text">-</span>
              </div>
            </template>
            <template v-else-if="item.config && item.config.type === 'medias'">
              <div class="flex flex-wrap" style="width: 100%;">
                <template v-if="image.type=='image'">
                  <el-image style="width: 90px; height: 90px" class="m-b-10 m-r-10" :src="image.url"
                    v-for="(image, ii) in item.config.name" fit="cover" :preview-src-list="[image.url]"
                    :key="ii"></el-image>
                </template>
                <template v-if="image.type=='video'">
                  <img @click="bofVideo(image,1)" style="width: 90px; height: 90px" class="m-b-10 m-r-10"
                    :src="image.thumbnail" v-for="(image, ii) in item.config.name" />
                </template>
              </div>
              <div class="flex flex-wrap" style="width: 100%;"
                v-if="!item.config.name || !item.config.name.length">
                <span type="text">-</span>
              </div>
            </template>
            <template v-else>{{item.config || '-'}}</template>
          </el-col>
        </el-row>
      </div>
    </el-dialog>

    <Pagination :total="pageData.total" :page="pageData.current_page" :change="changPage" />
  </div>
</template>

<script>
import { setDetail, getIndex, del } from "../api/signup-tube";
import Pagination from "@/base/components/Default/Pagination";
import TubeFilter from "../components/TubeFilter";
export default {
  components: { Pagination, TubeFilter },
  data() {
    return {
      id: 0,
      list: [],
      fields: [],
      detail: {},
      loading: false, //加载
      openDialog: false,
      saveLoading: false,
      //筛选对象
      filterForm: {
        id: this.$route.params.id,
        start_time: -1, //开始时间
        end_time: -1, //结束时间
        keyword: "", //组织名称
        page_size: 15,
      },
      pageData: {
        page_size: 15,
      },
    };
  },
  computed: {
    fieldList() {
      // 过滤掉头两个和尾
      return this.fields.filter(
        (obj, index) => index > 1 && index < this.fields.length - 1
      );
    },
  },
  created() {
    this.id = this.$route.params.id;

    this.getSpiritList(this.filterForm);
  },
  methods: {
    cancel() {
      this.openDialog = false;
    },
    exportList() {},
    toIndexPage() {
      this.$router.go(-1);
    },
    getSpiritList(requestData) {
      1;
      this.loading = true;
      getIndex(requestData).then((res) => {
        this.fields = res.data.field;
        this.list = res.data.list.data;
        this.pageData = res.data.list;
        this.loading = false;
      });
    },
    // 删除
    delItem(id) {
      this.$msgbox
        .confirm("确定要删除该数据吗？", "提示", {
          type: "error",
        })
        .then((res) => {
          this.loading = true;
          del({ id })
            .then((res) => {
              this.$message.success(res.msg);
              this.refreashList();
            })
            .catch(() => {
              this.refreashList();
            });
        })
        .catch((err) => {});
    },
    //表格排序
    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === "ascending"
        ? 0
        : 1;
      this.filterForm.order_by = column.prop;
      this.getSpiritList(this.filterForm);
    },
    showDetail(id) {
      this.saveLoading = true;
      this.openDialog = true;
      setDetail({ id: id })
        .then((res) => {
          this.detail = res.data;
          this.saveLoading = false;
        })
        .catch(() => {
          this.saveLoading = false;
        });
    },
    //刷新列表
    refreashList() {
      this.getSpiritList({
        ...this.filterForm,
        page: this.pageData.current_page,
      });
    },
    // 分页查询
    changPage(e) {
      this.filterForm.page_size = e.page_size;
      const pageData = { ...this.filterForm, ...e };
      this.getSpiritList(pageData);
    },
    // 筛选回调
    ok(e) {
      const pageData = {};
      this.filterForm = { ...this.filterForm, ...e, page: 1 };
      this.getSpiritList({ ...this.filterForm, ...pageData });
    },
  },
};
</script>

<style lang="scss" scoped>
.row-data + .row-data {
  margin-top: 10px;
}
</style>
